import http from '@/lib/http'
import { useQuery } from '@tanstack/react-query'
import { queryClient } from './queryClient'
import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import { useGetInvestigations } from './investigations/useGetInvestigations'

export const useLockItemById = (id: string, enabled: boolean, isInvestigation = false) => {
  const { data: medRequests } = useMedRequestFiltering(!isInvestigation)
  const { data: investigations } = useGetInvestigations(
    {},
    {
      enabled: enabled && isInvestigation
    }
  )

  useQuery({
    queryKey: [`lock-${id}`],
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const items = isInvestigation ? investigations : medRequests
      const item = items?.find(obj => obj.id === id)
      if (!item) {
        // console.error(`[useLockItemById] Item with id ${id} not found`);
        return false
      }

      const promiseItems = (items?.map(obj => (obj.patient.id === item.patient.id ? obj.id : null)) ?? [])
        .filter(entry => entry !== null)
        .map(id => http.post<boolean, boolean>(`/prescriber/med-requests/${id}/lock`))

      // console.log(
      //   "[useLockItemById] Locking items of total length:",
      //   promiseItems.length
      // );
      const [success] = await Promise.all(promiseItems)

      if (!success) {
        queryClient.invalidateQueries({ queryKey: ['medRequestFiltering'] })
        queryClient.invalidateQueries({ queryKey: [id] })
      }
      return true
    },
    enabled
  })
}
