import { cn } from '@/lib/utils'

export default function WarningRoundedIcon({ ...props }) {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn(props.className)}
      {...props}
    >
      <path
        d='M4.47 21.5002H19.53C21.07 21.5002 22.03 19.8302 21.26 18.5002L13.73 5.49018C12.96 4.16018 11.04 4.16018 10.27 5.49018L2.74 18.5002C1.97 19.8302 2.93 21.5002 4.47 21.5002ZM12 14.5002C11.45 14.5002 11 14.0502 11 13.5002V11.5002C11 10.9502 11.45 10.5002 12 10.5002C12.55 10.5002 13 10.9502 13 11.5002V13.5002C13 14.0502 12.55 14.5002 12 14.5002ZM13 18.5002H11V16.5002H13V18.5002Z'
        fill='#FF8080'
      />
    </svg>
  )
}
