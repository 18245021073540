import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import useMedRequestAction from './action'
import useBatchApproval from '@/hooks/useBatchApproval'
import { usePosthogCustom } from '@/hooks/usePosthogCustom'
import { convertDateToISOWithoutConverting } from '@/lib/utils'
import { useScrAiSummary } from '@/hooks/scr-ai-summary'
import { isAfter } from 'date-fns'

export default function useApproveMedicationRequest(props?: {
  patientOrigin?: 'medication-request' | 'investigation'
}) {
  const { patientOrigin = 'medication-request' } = props ?? {}

  const { medication, medicationIssueDate } = useMedicationRequestStore()
  const goNextMedRequest = useGoNextMedRequest(patientOrigin === 'medication-request')

  const medRequest = useCurrentMedication(patientOrigin === 'medication-request')
  const originalMedRequest = medRequest?.data

  const { data: batchApprovals = [], shouldUseBatchApprovalLogic } = useBatchApproval({
    medRequest: originalMedRequest
  })

  const { trackMedRequestAction } = usePosthogCustom()

  const action = useMedRequestAction({
    enabled: !!(patientOrigin === 'medication-request'),
    doNotResetStore: shouldUseBatchApprovalLogic
  })

  const { prompt: scrExecutedPrompt, summary: scrSummaryPrompt } = useScrAiSummary({
    medicationRequestId: originalMedRequest?.id ?? ''
  })

  return () => {
    if (!originalMedRequest) return

    let changeProduct = undefined
    if (
      typeof medication?.wooSKU === 'string' &&
      typeof originalMedRequest?.wooSKU === 'string' &&
      medication.wooSKU !== originalMedRequest?.wooSKU
    ) {
      changeProduct = {
        prevSKU: originalMedRequest?.wooSKU,
        newSKU: medication.wooSKU
      }
    }

    const { notes, medicationDirection, setStatus, setBatchOrders, justification, setJustification } =
      useMedicationRequestStore.getState()
    action.mutate({
      id: originalMedRequest.id,
      action: 'approve',
      reason: justification,
      approveDate: convertDateToISOWithoutConverting(medicationIssueDate),
      additionalNotes: notes,
      extraDirectionsForMedication: medicationDirection,
      changeProduct,
      scrExecutedPrompt: scrExecutedPrompt,
      scrSummaryResult: scrSummaryPrompt
    })

    trackMedRequestAction('approve', originalMedRequest.id)

    // Move to batch approval screen if not approved for future.
    const isForFuture = isAfter(medicationIssueDate, new Date())
    if (shouldUseBatchApprovalLogic && !isForFuture) {
      setBatchOrders(batchApprovals)
      setStatus('approved')
    } else {
      goNextMedRequest()
      setJustification('')
    }
  }
}
