import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { useGetInvestigations } from '@/services/investigations/useGetInvestigations'
import { useInvestigationRequestById, useMedicationRequestById } from '@/services/medicationRequests'
import { useLockItemById } from '@/services/useLockItem'
import { useParams } from 'react-router-dom'
import { useConsultationAISummary } from './useConsultationAISummary'
import type { MedRequest } from '@/types/data'
import { shouldLockInvestigation, shouldLockMedRequest } from '@/lib/permissions'
import { useScrAiSummary } from './scr-ai-summary'

const usePrepareNextRequest = (enabled = true, isInvestigation = false) => {
  const { id } = useParams<{ id: string }>()

  const { data: medRequests } = useMedRequestFiltering(!isInvestigation)
  const { data: investigations } = useGetInvestigations(
    {},
    {
      enabled: enabled && isInvestigation
    }
  )

  const items = isInvestigation ? investigations : medRequests
  const currentItemIndex = items?.findIndex(e => e.id === id) ?? 0
  let nextItem = items?.[(currentItemIndex + 1) % items.length]
  if (nextItem?.id === items?.[currentItemIndex]?.id) {
    nextItem = undefined
  }

  const investigation = useInvestigationRequestById(nextItem?.id ?? '', !!nextItem && enabled && isInvestigation)
  const medRequest = useMedicationRequestById(
    (isInvestigation ? investigation?.data?.medicationRequestId : nextItem?.id) ?? '',
    ((isInvestigation && !!investigation?.data) || !!nextItem) && enabled
  )

  const lockMedRequest = !isInvestigation ? (medRequest?.data ? shouldLockMedRequest(medRequest.data) : false) : true
  const lockInvestigation = isInvestigation && investigation?.data ? shouldLockInvestigation() : false

  useLockItemById(
    nextItem?.id ?? '',
    !!nextItem && enabled && (isInvestigation ? lockInvestigation : lockMedRequest),
    isInvestigation
  )
  usePatientOrderHistory(nextItem?.patient?.id ?? '', !!nextItem && enabled)
  useConsultationAISummary(!isInvestigation ? (nextItem as MedRequest) : undefined)

  useScrAiSummary({
    medicationRequestId: nextItem?.id ?? '',
    enabled: !isInvestigation
  })

  return nextItem
}

export default usePrepareNextRequest
