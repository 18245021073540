export default function BlackStarIcon({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 9.39002L12.94 12.5H15.76L13.49 14.12L14.42 17.13L12 15.29L9.58002 17.13L10.51 14.12L8.24002 12.5H11.06L12 9.39002ZM9.58002 10.5H5.12002C4.15002 10.5 3.75002 11.75 4.54002 12.31L8.18002 14.91L6.75002 19.52C6.46002 20.45 7.54002 21.2 8.31002 20.61L12 17.81L15.69 20.62C16.46 21.21 17.54 20.46 17.25 19.53L15.82 14.92L19.46 12.32C20.25 11.75 19.85 10.51 18.88 10.51H14.42L12.95 5.67002C12.66 4.72002 11.32 4.72002 11.04 5.67002L9.58002 10.5Z'
        fill='#1D1D1D'
      />
    </svg>
  )
}
