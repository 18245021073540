import { ClerkProvider } from '@clerk/clerk-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { queryClient } from './services/queryClient.ts'
import './global.css'
import { initializeSentry } from './sentry.client.config.ts'
import { FlagProvider } from '@unleash/proxy-client-react'
import { PostHogProvider } from 'posthog-js/react'
import type { PostHogConfig } from 'posthog-js'

initializeSentry()

const posthogOptions: Partial<PostHogConfig> = {
  api_host: '/ingest',
  autocapture: false,
  capture_pageleave: false,
  capture_pageview: false,
  capture_performance: false
}

const config = {
  url: 'https://eu.app.unleash-hosted.com/eucc0021/api/frontend/',
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'prescriber'
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ClerkProvider
        signInUrl={import.meta.env.VITE_CLERK_SIGN_IN_URL}
        signUpUrl={import.meta.env.VITE_CLERK_SIGN_UP_URL}
        publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      >
        <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={posthogOptions}>
          <FlagProvider config={config}>
            <App />
          </FlagProvider>
        </PostHogProvider>
      </ClerkProvider>
    </QueryClientProvider>
  </StrictMode>
)
