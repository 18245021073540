import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { ConsultationTable } from '../consultation-table'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { useConsultationAISummary } from '@/hooks/useConsultationAISummary'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import { cn, getAiRespTextClassName, useConsultationSummaryFlag } from '@/lib/utils'
import InfoRounded from '@/components/ui/icons/info-rounded'
import { useEffect, useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import AiButton from '@/components/ui/ai-button'
import BlackStarIcon from '@/components/ui/icons/black-star-icon'

export function SuggestedActionBadge({
  suggestedActionTag
}: {
  suggestedActionTag: string
}) {
  if (!['HUMAN REVIEW', 'REJECT', 'APPROVE', 'PLACE ON HOLD'].includes(suggestedActionTag)) {
    return null
  }

  const variants: Record<string, string> = {
    'HUMAN REVIEW': 'bg-gray-50 border-gray-500 text-gray-500',
    REJECT: 'bg-red-50 border-red-500 text-red-500',
    APPROVE: 'bg-green-50 border-green-500 text-green-500',
    'PLACE ON HOLD': 'bg-yellow-50 border-yellow-500 text-yellow-500'
  }
  const actionTagClassName = variants[suggestedActionTag] ?? ''

  return (
    <div className={cn('px-3 py-1.5 rounded-[8px] font-bold text-sm font-poppins border', actionTagClassName)}>
      {suggestedActionTag}
    </div>
  )
}

export default function Consultation() {
  const medRequest = useCurrentMedication()
  const { parsedQuestions } = useQuestionnaire(medRequest?.data?.questionaire ?? '')

  const isConsultationSummaryEnabled = useConsultationSummaryFlag(medRequest?.data)
  const [consultationDataAccordionValue, setConsultationDataAccordionValue] = useState<string | undefined>(
    !isConsultationSummaryEnabled ? 'consultation-data' : undefined
  )
  useEffect(() => {
    if (!isConsultationSummaryEnabled) {
      setConsultationDataAccordionValue('consultation-data')
    }
  }, [isConsultationSummaryEnabled])

  const { mainText, attentionText, isFetching, invalidate, suggestedAction, suggestedActionTag, suggestedDescription } =
    useConsultationAISummary(medRequest.data)

  if (!parsedQuestions) return null

  return (
    <div id='consultation' className='flex flex-col gap-[2.3rem]'>
      {isConsultationSummaryEnabled && (
        <div className='flex items-center gap-2'>
          <h2 className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Consultation Data Summary</h2>
          {isFetching && <LoadingSpinner className='w-6 h-6 text-custom-neutral-900 animate-spin' />}
        </div>
      )}

      {!!mainText.length && (
        <div className='flex flex-col gap-4'>
          <div className='flex items-center bg-[#FAFAFB] border border-[#E9EAED] py-4 px-6 gap-6 rounded-3xl'>
            <InfoRounded className='min-w-6 min-h-6' />
            <span className='text-base leading-7 font-poppins'>
              Please note, this summary has been generated by AI and may be incorrect. Prescribers are clinically
              required to read the full Consultation data before making a decision.
            </span>
          </div>
          <div className='flex flex-col bg-[#FAFAFB] border border-[#E9EAED] p-8 gap-6 rounded-3xl relative'>
            {isFetching && (
              <div className='absolute top-0 left-0 w-full h-full bg-gray-100/10 animate-fade-in backdrop-blur-md grid place-items-center rounded-3xl'>
                <LoadingSpinner className='w-[80px] h-[80px] text-custom-neutral-900 animate-spin' />
              </div>
            )}
            {!!(suggestedAction && suggestedDescription) && (
              <div className='border border-black/80 p-4 flex flex-col gap-4 rounded-2xl bg-white'>
                <div className='flex items-center gap-2'>
                  <BlackStarIcon />
                  <span className='text-[#1D1D1D] text-base font-semibold font-poppins'>Suggested action</span>
                  <SuggestedActionBadge suggestedActionTag={suggestedActionTag} />
                </div>

                <div className='flex flex-col gap-2'>
                  <span className='text-base font-semibold font-poppins'>{suggestedAction}</span>

                  <span className='text-base font-normal font-poppins'>{suggestedDescription}</span>
                </div>
              </div>
            )}

            <div className='flex flex-col gap-1'>
              {mainText.map((text, idx) => (
                <p
                  key={idx}
                  className={cn(
                    'text-custom-neutral-800 text-sm font-normal leading-5',
                    getAiRespTextClassName({
                      allText: mainText,
                      index: idx
                    })
                  )}
                >
                  {text}
                </p>
              ))}
            </div>

            {attentionText?.length > 0 && (
              <div className='rounded-3xl border border-[#FF8080] flex flex-col'>
                <h3 className='font-poppins font-medium text-base leading-7 px-4 py-3 bg-[#FFE5E5] text-[#1D1D1D] rounded-t-3xl'>
                  Patient flags that may need attention
                </h3>
                <div className='flex flex-col gap-1 p-4'>
                  {attentionText.map((text, idx) => (
                    <p
                      key={idx}
                      className={cn(
                        'text-custom-neutral-800 text-sm font-normal leading-5',
                        getAiRespTextClassName({
                          allText: attentionText,
                          index: idx
                        })
                      )}
                    >
                      {text}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {isConsultationSummaryEnabled && (
              <AiButton disabled={isFetching} onClick={invalidate}>
                Regenerate summary
              </AiButton>
            )}
          </div>
        </div>
      )}

      <Accordion
        value={consultationDataAccordionValue}
        type='single'
        collapsible
        className='border border-[#D1D9E2] rounded-3xl p-8'
      >
        <AccordionItem value='consultation-data' className='flex flex-col gap-6 border-0'>
          <AccordionTrigger
            className='hover:no-underline py-0'
            onClick={e => {
              e.preventDefault()
              setConsultationDataAccordionValue(prev => (prev ? undefined : 'consultation-data'))
            }}
          >
            <h2 className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Consultation Data</h2>
          </AccordionTrigger>
          <AccordionContent>
            <ConsultationTable data={parsedQuestions} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
