import { useEffect } from 'react'
import { usePosthogCustom } from './usePosthogCustom'
const useTrackTimeSpent = () => {
  const { trackTimeSpent } = usePosthogCustom()

  useEffect(() => {
    const interval = setInterval(() => {
      trackTimeSpent(60) // 60 seconds
    }, 60000) // 60 seconds = 60000 milliseconds

    return () => clearInterval(interval)
  }, [])
}

export default useTrackTimeSpent
