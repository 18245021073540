import ContactPatientSection from '../medication-request/sections/contact-patient-section'
import NavigationMenu from '../medication-request/sections/nav-menu'
import PatientData from '../medication-request/sections/patient-data'
import PreviousOrders from '../medication-request/sections/previous-orders'

import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import ApprovalReasonSection from '../medication-request/sections/approval-reason-section'
import Header from './header'
import PreviousInvestigations from '../medication-request/sections/previous-investigations'
import { useInvestigationStore } from '@/lib/stores/investigation'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import AdditionalNotes from '../medication-request/sections/additional-notes'
import { useUser } from '@clerk/clerk-react'
import { TestsResultsTable } from './tests-results-table'
import InvestigationFooter from './footer'
import { isRoleAllowedToEditInvestigations, isRoleAllowedToSeeFooterInInvestigation } from '@/lib/permissions'

const InvestigationPage = () => {
  const { user } = useUser()
  const { data: investigation } = useCurrentInvestigation()
  const { sidebarView } = useInvestigationStore()
  const userRoles = user?.publicMetadata.roles ?? []

  const isJunior = userRoles.includes('JUNIOR_PRESCRIBER')

  const hideFooter = sidebarView !== 'navigation-menu' || !isRoleAllowedToSeeFooterInInvestigation(userRoles)

  // [19 DEC]: Junior shouldnt be able to visit this page
  if (userRoles.includes('JUNIOR_PRESCRIBER')) {
    throw new Error('Junior prescribers are not allowed to see this page')
  }

  if (!investigation) return null

  const hasTestResults = !!investigation.results?.tests?.map(entry => entry.resultLines).flat().length

  const isEditable = isRoleAllowedToEditInvestigations()

  const hideAdditionalNotes = false
  // Juniors should not be able to see any additional notes as per request.
  // userRoles.includes("JUNIOR_PRESCRIBER"))

  return (
    <section key={investigation?.id} className='flex flex-col gap-8'>
      <Header />
      <div className='flex gap-[37px] flex-nowrap w-full justify-between'>
        <div className='flex flex-col gap-14 w-full'>
          <PatientData patientOrigin='investigation' />
          <ErrorBoundary fallback='Error Loading Previous Orders'>
            <PreviousOrders patientId={investigation.patient.id} patientOrigin='investigation' />
          </ErrorBoundary>

          {!isJunior && (
            <ErrorBoundary fallback='Error Loading Previous Investigations'>
              <PreviousInvestigations patientId={investigation.patient.id} withoutInvestigationId={investigation.id} />
            </ErrorBoundary>
          )}

          {!!investigation.results?.tests?.length && (
            <TestsResultsTable investigationResults={investigation.results.tests} />
          )}

          {!hideAdditionalNotes && (
            <ErrorBoundary fallback='Error loading additional notes'>
              <AdditionalNotes patientOrigin='investigation' isEditable={isEditable} />
            </ErrorBoundary>
          )}
        </div>
        {sidebarView === 'navigation-menu' && (
          <ErrorBoundary fallback='Error loading navigation menu'>
            <NavigationMenu
              patientId={investigation.patient.id}
              patientOrigin='investigation'
              withoutInvestigationId={investigation.id}
              hasTestResults={hasTestResults}
              isInvestigation
            />
          </ErrorBoundary>
        )}
        {sidebarView === 'approve' && <ApprovalReasonSection patientOrigin='investigation' />}
        {sidebarView === 'contact' && <ContactPatientSection patientOrigin='investigation' />}
      </div>
      {!hideFooter && <InvestigationFooter />}
    </section>
  )
}

export default InvestigationPage
