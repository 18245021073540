import { Textarea } from '@/components/ui/textarea'
import type { ComponentPropsWithoutRef } from 'react'
import { cn } from '@/lib/utils'

type Props = {
  title?: string
  hasError?: boolean
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  value?: string
  isDisabled?: boolean
  defaultValue?: string | null
} & ComponentPropsWithoutRef<'textarea'>

const TextareaWithLabel = ({
  value,
  title,
  hasError,
  placeholder,
  onChange,
  isDisabled = false,
  defaultValue,
  ...props
}: Props) => {
  return (
    <div className='relative'>
      {title && (
        <div
          className={`absolute left-4 -top-2 text-xs leading-3 tracking-[0.15px] ${
            hasError ? 'text-red-500' : 'text-custom-neutral-800'
          } p-1 bg-white rounded-lg shadow-sm z-10`}
        >
          {title}
        </div>
      )}
      <Textarea
        disabled={isDisabled}
        value={value}
        defaultValue={defaultValue ?? ''}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
        className={cn(
          'resize-none h-[148px] py-4 px-3 rounded-2xl focus:ring-2 focus:ring-custom-blue-500 focus:border-transparent',
          hasError ? 'border-red-500' : 'border-custom-neutral-200',
          props.className
        )}
      />
    </div>
  )
}

export default TextareaWithLabel
