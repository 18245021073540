import { cn } from '@/lib/utils'
import { useEffect, type ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Logo from '../ui/icons/logo'
import { Toaster } from '../ui/toaster'
import UserWidget from '../ui/user'
import { useClerk } from '@clerk/clerk-react'

const USER_ACTIVITY_TIMEOUT = 1000 * 60 * 10 // 10 minutes

export default function RootLayout({ children }: { children: ReactNode }) {
  const { signOut } = useClerk()
  const location = useLocation()
  const selected = location.pathname

  // Automatically sign out the user after a period of inactivity.
  useEffect(() => {
    const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development'
    if (isDevelopment) return

    const eventTypes = ['mouseup', 'keydown', 'scroll', 'mousemove']

    let timeoutId = -1
    const deferSignOut = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(signOut, USER_ACTIVITY_TIMEOUT)
    }

    eventTypes.forEach(eventType => {
      document.addEventListener(eventType, deferSignOut)
    })

    // Also fire on initial load because the user might not interact.
    deferSignOut()

    return () => {
      eventTypes.forEach(eventType => {
        document.removeEventListener(eventType, deferSignOut)
      })
    }
  }, [])

  return (
    <main className='min-h-[100vh] w-full flex flex-col'>
      <div className='fixed left-0 top-0 w-full flex items-center justify-between py-3 px-8 border-b border border-custom-neutral-700 bg-white z-[10]'>
        <Link to='/'>
          <Logo />
        </Link>

        <nav className='flex items-center gap-[4.75rem]'>
          <Link
            to='/'
            className={cn(
              'text-base leading-4 transition-all duration-200 ease-in-out text-custom-neutral-600 font-medium',
              selected === '/' && 'font-bold text-secondary'
            )}
          >
            Dashboard
          </Link>
          <Link
            to='/history'
            className={cn(
              'text-base leading-4 transition-all duration-200 ease-in-out text-custom-neutral-600 font-medium',
              selected === '/history' && 'font-bold text-secondary'
            )}
          >
            History
          </Link>
        </nav>

        <UserWidget />
      </div>

      <div className='mt-[8rem] relative px-8 pb-[8.125rem]'>{children}</div>
      <Toaster />
    </main>
  )
}
