import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import useApproveMedicationRequest from '@/lib/api/med-requests/approve'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { cn } from '@/lib/utils'
import { useUser } from '@clerk/clerk-react'
import { isAfter } from 'date-fns'
import { useEffect, useState } from 'react'

export default function Footer() {
  const { medicationIssueDate, setSidebarView, medicationDirection, medication } = useMedicationRequestStore()
  const { user } = useUser()

  const isForFuture = isAfter(medicationIssueDate, new Date())

  const [isScrolledDown, setIsScrolledDown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const { medication } = useMedicationRequestStore.getState()
      if (medication?.type === 'OTC') return

      const scrollPosition = window.innerHeight + window.scrollY
      const documentHeight = document.documentElement.scrollHeight
      const isAtBottom = scrollPosition >= documentHeight * 0.92 // 80% of viewport
      if (!isScrolledDown && isAtBottom) setIsScrolledDown(true)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Check initial scroll position

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const medRequest = useCurrentMedication()
  const data = medRequest?.data

  const approveMedicationRequest = useApproveMedicationRequest()
  if (!approveMedicationRequest) return null

  const goNextMedRequest = useGoNextMedRequest()

  const isApprovalDisabled =
    data?.locked ||
    ((!isScrolledDown || medicationDirection.length === 0 || !medication) &&
      !data?.isPriority &&
      medication?.type !== 'OTC')

  const roles = user?.publicMetadata.roles ?? []
  const isPharmacist = roles.includes('PHARMACIST')
  const showSkipButton = isPharmacist || data?.locked

  if (!data) return null

  return (
    <div className='fixed left-0 bottom-0 flex w-full h-[88px] flex-col justify-center items-center flex-shrink-0 border-t border-[#FAFAFB] bg-white shadow-[0px_-8px_80px_0px_rgba(0,0,0,0.10)] z-[99]'>
      <div className='flex justify-center gap-4 w-full max-w-[994px]'>
        <button
          type='button'
          onClick={() => {
            if (medication?.type === 'OTC') {
              approveMedicationRequest()
            } else {
              setSidebarView('approval-reason')
            }
          }}
          className={`flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl bg-[#153CB7] py-4 px-6 hover:bg-[#0F2E8A] transition-colors duration-200 ${
            isApprovalDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isApprovalDisabled}
        >
          <span className='text-white text-center text-sm font-semibold leading-[170%]'>
            {isForFuture ? 'Approve for future' : 'Approve Now'}
          </span>
        </button>
        <button
          disabled={data?.locked}
          type='button'
          onClick={() => {
            setSidebarView('contact-patient')
          }}
          className={cn(
            'flex w-[200px] h-14 flex-col justify-center items-center rounded-2xl bg-white py-4 px-6 border border-custom-neutral-200 hover:bg-custom-neutral-200 transition-colors duration-200',
            {
              'opacity-50 cursor-not-allowed': data?.locked
            }
          )}
        >
          <span className='text-[#080F30] text-center text-sm font-semibold leading-[170%]'>Follow Up</span>
        </button>
        <button
          disabled={data?.locked}
          type='button'
          onClick={() => {
            setSidebarView('rejection')
          }}
          className={cn(
            'flex w-[200px] h-14 flex-col justify-center items-center rounded-2xl bg-white py-4 px-6 border border-custom-neutral-200 hover:bg-custom-neutral-200 transition-colors duration-200',
            {
              'opacity-50 cursor-not-allowed': data?.locked
            }
          )}
        >
          <span className='text-[#080F30] text-center text-sm font-semibold leading-[170%]'>Reject</span>
        </button>

        {showSkipButton && (
          <button
            type='button'
            onClick={() => {
              goNextMedRequest()
            }}
            className='flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl bg-[#153CB7] py-4 px-6 hover:bg-[#0F2E8A] transition-colors duration-200'
          >
            <span className='text-white text-center text-sm font-semibold leading-[170%]'>Skip to Next</span>
          </button>
        )}
      </div>
    </div>
  )
}
