import { useQuery } from '@tanstack/react-query'
import { useQuestionnaire } from './useQuestionnaire'
import type { MedRequest } from '@/types/data'
import http from '@/lib/http'
import { useConsultationSummaryFlag } from '@/lib/utils'

export const parseSuggestedActionTag = (suggestedAction: string) => {
  for (const tag of ['HUMAN REVIEW', 'PLACE ON HOLD', 'APPROVE', 'REJECT']) {
    if (suggestedAction.startsWith(tag)) {
      return tag
    }
  }

  return ''
}

export const useConsultationAISummary = (medRequest?: MedRequest) => {
  const consultationSummaryEnabled = useConsultationSummaryFlag(medRequest)
  const { parsedQuestions } = useQuestionnaire(medRequest?.questionaire ?? '')

  const query = useQuery({
    queryKey: ['aiSummary', medRequest?.id],
    queryFn: async () => {
      if (medRequest?.orderMedNameString && parsedQuestions.length > 0) {
        const { prompt, summary } = (await http.get(`/prescriber/dental-ai/${medRequest.id}`)) as {
          prompt: string
          summary: string
        }

        let [suggestedAction, afterSuggested] = summary.split('</SUGGESTED_ACTION>')
        suggestedAction = suggestedAction.replace('<SUGGESTED_ACTION>', '')

        let [suggestedDescription, rest] = afterSuggested.split('</SUGGESTED_ACTION_DESCRIPTION>')
        suggestedDescription = suggestedDescription.replace('<SUGGESTED_ACTION_DESCRIPTION>', '')

        const [mainText, attentionText] = rest.split('<PATIENT_FLAGS_MAY_NEED_ATTENTION>')

        const suggestedActionTag = parseSuggestedActionTag(suggestedAction)

        return {
          suggestedActionTag,
          suggestedAction: suggestedAction.replace(suggestedActionTag, ''),
          suggestedDescription,
          attentionText: (attentionText ?? '')
            .replace('<PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
            .replace('</PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
            .split('\n')
            .filter(Boolean),
          mainText: (mainText ?? '').split('\n').filter(Boolean),
          prompt: prompt,
          summary: summary
        }
      }

      return {
        suggestedActionTag: '',
        suggestedAction: '',
        suggestedDescription: '',
        attentionText: [],
        mainText: [],
        prompt: '',
        summary: ''
      }
    },
    enabled: consultationSummaryEnabled
  })

  return {
    ...query,
    invalidate: () => query.refetch(),
    suggestedActionTag: query.data?.suggestedActionTag ?? '',
    suggestedAction: query.data?.suggestedAction ?? '',
    suggestedDescription: query.data?.suggestedDescription ?? '',
    mainText: query.data?.mainText ?? [],
    attentionText: query.data?.attentionText ?? [],
    summary: query.data?.summary ?? '',
    prompt: query.data?.prompt ?? ''
  }
}
