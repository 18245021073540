import { useQuery } from '@tanstack/react-query'
import http from '@/lib/http'
import { queryClient } from '@/services/queryClient'
import { useFlag } from '@unleash/proxy-client-react'

interface UseScrAiSummaryProps {
  medicationRequestId: string
  enabled?: boolean
}

export const useScrAiSummary = ({ medicationRequestId, enabled = true }: UseScrAiSummaryProps) => {
  const shouldGetScrSummary = useFlag('scr-summary')

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['scrAiSummary', medicationRequestId]
    })
  }

  const query = useQuery({
    queryKey: ['scrAiSummary', medicationRequestId],
    queryFn: async () => {
      const { prompt, summary } = (await http.get(`/prescriber/scr-ai/${medicationRequestId}`)) as {
        prompt: string
        summary: string
      }

      const [mainText, attentionText] = summary.split('<PATIENT_FLAGS_MAY_NEED_ATTENTION>')

      return {
        attentionText: attentionText
          .replace('<PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
          .replace('</PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
          .split('\n'),
        mainText: mainText.split('\n'),
        prompt: prompt,
        summary: summary
      }
    },
    enabled: !!(enabled && shouldGetScrSummary && medicationRequestId)
  })

  return {
    ...query,
    mainText: query.data?.mainText ?? [],
    attentionText: query.data?.attentionText ?? [],
    summary: query.data?.summary ?? '',
    prompt: query.data?.prompt ?? '',
    invalidate
  }
}
