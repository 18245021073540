import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Textarea } from '@/components/ui/textarea'
import { useAiSettings } from '@/services/ai-settings'
import { useToast } from '@/hooks/use-toast'
import { useState } from 'react'

export default function AiSettings() {
  const { toast } = useToast()
  const { aiSettings, updateIsPending, updateScrAiTemplate } = useAiSettings()
  const [draftTemplate, setDraftTemplate] = useState<string>(aiSettings?.draftTemplatePrompt ?? '')

  return (
    <div className='flex flex-col gap-6'>
      <h2 className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>AI Settings</h2>

      <div className='flex flex-col gap-4'>
        <h3 className='text-custom-neutral-600 text-lg font-medium'>SCR prompting</h3>

        {aiSettings?.activeTemplatePrompt ? (
          <div className='flex flex-col gap-2'>
            <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
              The current <strong>active</strong> prompt template is as follows:
            </p>
            <div className='bg-gray-200 py-4 px-3 rounded-lg text-sm leading-5'>{aiSettings?.activeTemplatePrompt}</div>
          </div>
        ) : (
          <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
            There is no active prompt template. Edit the draft and publish it!
          </p>
        )}

        <div className='flex flex-col gap-2'>
          <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
            Edit the draft view template of the SCR AI:
          </p>
          <div className=''>
            <Textarea
              value={draftTemplate}
              placeholder='Enter the draft view template of the SCR AI'
              onChange={e => setDraftTemplate(e.target.value)}
              className='bg-gray-200 py-4 px-3 rounded-lg text-sm leading-5 resize-none h-[244px] focus:ring-2 focus:ring-custom-blue-500 focus:border-transparent'
            />
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-3'>
            <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
              View the Prescriber App with your draft template:
            </p>

            <Checkbox
              disabled={updateIsPending}
              checked={aiSettings.draftView}
              onCheckedChange={value => {
                updateScrAiTemplate(
                  { draftView: value as boolean },
                  {
                    onSuccess: () => {
                      toast({
                        title: 'Success',
                        description: value ? 'Draft view is now enabled' : 'Draft view is now disabled'
                      })
                    }
                  }
                )
              }}
            />
          </div>
        </div>

        <div className='flex items-center gap-3 self-end'>
          <Button
            disabled={aiSettings.activeTemplatePrompt === draftTemplate || updateIsPending}
            onClick={() => {
              updateScrAiTemplate(
                {
                  publish: true,
                  template: draftTemplate
                },
                {
                  onSuccess: () => {
                    toast({
                      title: 'Success',
                      description: 'Published as active'
                    })
                  }
                }
              )
            }}
          >
            Publish as Active
          </Button>
          <Button
            disabled={aiSettings.draftTemplatePrompt === draftTemplate || updateIsPending}
            onClick={() => {
              updateScrAiTemplate(
                { template: draftTemplate },
                {
                  onSuccess: () => {
                    toast({
                      title: 'Success',
                      description: 'Saved draft template'
                    })
                  }
                }
              )
            }}
          >
            Save Draft
          </Button>
        </div>
      </div>
    </div>
  )
}
