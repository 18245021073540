import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import http from '@/lib/http'
import { queryClient } from '@/services/queryClient'

export const useAiSettings = () => {
  const { data: aiSettings, isLoading: aiSettingsLoading } = useSuspenseQuery({
    queryKey: ['aiSettings'],
    queryFn: async () =>
      http.get('/prescriber/scr-ai/settings') as Promise<{
        activeTemplatePrompt: string
        draftTemplatePrompt: string
        draftView: boolean
      }>,
    refetchOnMount: true
  })

  const { isPending: updateIsPending, mutate: updateScrAiTemplate } = useMutation({
    mutationKey: ['scrAiTemplateUpdate'],
    mutationFn: async ({
      template,
      publish,
      draftView
    }: {
      template?: string
      publish?: boolean
      draftView?: boolean
    }) => {
      const result = await http.post('/prescriber/scr-ai/update', {
        template,
        publish,
        draftView
      })
      queryClient.invalidateQueries({ queryKey: ['aiSettings'] })
      return result
    }
  })

  return {
    aiSettings,
    aiSettingsLoading,
    updateIsPending,
    updateScrAiTemplate
  }
}
