import type { MedRequest } from '@/types/data'

const getRoles = () => {
  return window?.Clerk?.user?.publicMetadata.roles ?? []
}

export function isRoleAllowedToEditMedRequest(medRequest: MedRequest) {
  const roles = getRoles()

  const isPublicOrder = medRequest.OTC || medRequest.epharmacyVendor === '7a99527ae705fe76a25f1d70cf802b'

  if (roles.includes('PHARMACIST') && isPublicOrder) {
    return true
  }

  if (
    (['LEAD_PRESCRIBER', 'PRESCRIBER', 'JUNIOR_PRESCRIBER'] as const).some(role => roles.includes(role)) &&
    isPublicOrder
  ) {
    return false
  }

  const nonEditingRoles = ['VIEWER', 'DIETITIAN']
  if (roles.some(role => nonEditingRoles.includes(role))) return false

  return true
}

export function isRoleAllowedToActionMedRequest(medRequest?: MedRequest) {
  const roles = getRoles()

  if (!medRequest) return false

  const isPublicOrder = medRequest.orderProcessType === 'public'
  return roles.includes('PHARMACIST') ? isPublicOrder || medRequest?.OTC : true
}

export function isRoleAllowedToEditPatientData() {
  const roles = getRoles()

  const allowedRoles = ['PRESCRIBER', 'LEAD_PRESCRIBER', 'PHARMACIST', 'DIETITIAN']
  return roles.some(role => allowedRoles.includes(role))
}

export function isRoleAllowedToSeeFooterInInvestigation() {
  const roles = getRoles()

  const nonAllowedRoles = ['PHARMACIST', 'VIEWER', 'DIETITIAN']
  return !roles.some(role => nonAllowedRoles.includes(role))
}

export function isRoleAllowedToEditInvestigations() {
  const roles = getRoles()

  const nonAllowedRoles = ['PHARMACIST', 'VIEWER', 'DIETITIAN']
  return !roles.some(role => nonAllowedRoles.includes(role))
}

export function shouldLockMedRequest(medRequest: MedRequest) {
  const roles = getRoles()

  if (roles.includes('VIEWER') || roles.includes('DIETITIAN')) return false

  if (roles.includes('LEAD_PRESCRIBER')) {
    return !medRequest.OTC && medRequest.orderProcessType !== 'public'
  }

  if (roles.includes('PRESCRIBER')) {
    return !medRequest.OTC && !medRequest.trainingMode && medRequest.orderProcessType !== 'public'
  }

  if (roles.includes('JUNIOR_PRESCRIBER')) {
    return (
      !medRequest.OTC &&
      !medRequest.trainingMode &&
      !medRequest.juniorPrescriberRejected &&
      medRequest.orderProcessType !== 'public'
    )
  }

  if (roles.includes('PHARMACIST')) {
    return medRequest.OTC || medRequest.orderProcessType === 'public'
  }

  return true
}

export function shouldLockInvestigation() {
  const roles = getRoles()
  if (roles.includes('VIEWER') || roles.includes('PHARMACIST') || roles.includes('DIETITIAN')) return false
  return true
}
