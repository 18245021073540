import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useEffect, useMemo, type ChangeEvent } from 'react'
import TextareaWithLabel from '../textarea'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import WarningRoundedIcon from '@/components/ui/icons/warning-rounded'

interface QuestionProps {
  question: string
  onChange: (question: string, value: string) => void
  type: 'radio' | 'textarea'
  options?: string[]
  followUps?: {
    triggerValue: string
    question: string
    type: string
  }[]
  isEditable: boolean
}

const Question = ({ options, type, followUps, question, onChange, isEditable }: QuestionProps) => {
  const isTextarea = type === 'textarea'

  const { safeguardingQuestions } = useMedicationRequestStore()

  const value = safeguardingQuestions[question]

  const className = `flex ${
    isTextarea ? 'flex-col py-2 gap-4 mb-4' : 'flex-row py-4 gap-20 items-center'
  } justify-between`

  return (
    <>
      <div className={className}>
        <p className='text-[#080F30] text-base font-medium leading-[170%] w-1/2'>{question}</p>
        {type === 'radio' ? (
          <div className='flex gap-4 w-1/2 items-start'>
            {options?.map((option, index) => (
              <label key={index} className='flex items-center gap-4 mr-4'>
                <input
                  disabled={!isEditable}
                  type='radio'
                  value={option}
                  checked={value === option}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(question, e.target.value)}
                />
                {option}
              </label>
            ))}
          </div>
        ) : (
          <div className='px-1'>
            <TextareaWithLabel
              disabled={!isEditable}
              defaultValue={value as string}
              className='py-2.5 px-3.5 rounded-lg border resize-none border-[#D2D5D9]'
              placeholder='Type your answer here...'
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(question, e.target.value)}
            />
          </div>
        )}
      </div>
      {followUps && (
        <div className='mt-4'>
          {followUps.map((followUp, i) => {
            if (value === followUp.triggerValue) {
              return (
                <Question
                  isEditable={isEditable}
                  key={i}
                  question={followUp.question}
                  type={followUp.type as any}
                  onChange={onChange}
                />
              )
            }
            return null
          })}
        </div>
      )}
    </>
  )
}

const form = [
  {
    question: 'Is this patient suffering from a terminal illness?',
    type: 'radio',
    options: ['No', 'Yes'],
    followUps: [
      {
        triggerValue: 'Yes',
        question: 'What symptoms are not controlled?',
        type: 'textarea'
      }
    ]
  },
  {
    question: 'Does the patient need assistance with their activities of daily living?',
    type: 'radio',
    options: ['No', 'Yes'],
    followUps: [
      {
        triggerValue: 'Yes',
        question:
          'Which activities of daily living are they currently struggling with that have unmet assistance needs?',
        type: 'textarea'
      },
      {
        triggerValue: 'Yes',
        question: 'Who will you refer the patient to for further activities of daily living assistance?',
        type: 'textarea'
      }
    ]
  },
  {
    question: 'Is the patient at risk of harm, abuse or neglect?',
    type: 'radio',
    options: ['No', 'Yes'],
    followUps: [
      {
        triggerValue: 'Yes',
        question: 'Who will you refer the patient to for additional safeguarding?',
        type: 'textarea'
      },
      {
        triggerValue: 'Yes',
        question: 'In what way are they at risk of harm, abuse or neglect?',
        type: 'textarea'
      }
    ]
  },
  {
    question:
      'Do you have any doubts about the patient’s capacity to consent to this treatment (NB: consent is time-specific and decision-specific)?',
    type: 'radio',
    options: ['No', 'Yes'],
    followUps: [
      {
        triggerValue: 'Yes',
        question: 'Who are you involving?',
        type: 'textarea'
      },
      {
        triggerValue: 'Yes',
        question: 'What treatment decision are you making and why is this in their best interests?',
        type: 'textarea'
      }
    ]
  }
]

const defaultState = {
  'Is this patient suffering from a terminal illness?': 'No',
  'Does the patient need assistance with their activities of daily living?': 'No',
  'Is the patient at risk of harm, abuse or neglect?': 'No',
  'Do you have any doubts about the patient’s capacity to consent to this treatment (NB: consent is time-specific and decision-specific)?':
    'No'
}

interface Props {
  isEditable: boolean
}

const SafeguardingQuestions = ({ isEditable }: Props) => {
  const { setSafeguardingQuestions, safeguardingQuestions } = useMedicationRequestStore()

  const handleInputChange = (question: string, value: string) => {
    setSafeguardingQuestions({ ...safeguardingQuestions, [question]: value })
  }

  const { data } = useCurrentMedication()

  const previousAnswers = useMemo(
    () => data?.safeguardingQuestions ?? data?.patient?.safeguardingQuestions ?? defaultState,
    [data?.safeguardingQuestions, data?.patient?.safeguardingQuestions]
  )

  useEffect(() => {
    setSafeguardingQuestions(previousAnswers)
  }, [previousAnswers])

  const showExclamationIcon = Object.values(safeguardingQuestions).includes('Yes')

  return (
    <div id='safeguarding-questions' className='rounded-2xl border border-custom-neutral-700 bg-white p-8'>
      <Accordion type='single' collapsible>
        <AccordionItem className='border-0' value='item'>
          <AccordionTrigger chevronClassName='w-5 h-5 fill-[#4E545E]' className='hover:no-underline'>
            <div className='flex items-center mb-4'>
              <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mr-2'>
                Safeguarding questions
              </p>
              {showExclamationIcon && <WarningRoundedIcon />}
            </div>
          </AccordionTrigger>
          <AccordionContent className='pb-0'>
            {form.map((question, index) => {
              const isLastItem = index === form.length - 1
              return (
                <div key={index}>
                  <Question
                    isEditable={isEditable}
                    followUps={question.followUps}
                    type={question.type as any}
                    options={question.options}
                    question={question.question}
                    onChange={handleInputChange}
                  />
                  {!isLastItem && <hr className='my-6 border-custom-neutral-200' />}
                </div>
              )
            })}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default SafeguardingQuestions
