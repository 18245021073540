import { useMedicationRequestById } from '@/services/medicationRequests'
import { useParams } from 'react-router-dom'
import usePrepareNextRequest from './usePrepareNextRequest'
import { useLockItemById } from '@/services/useLockItem'
import { shouldLockMedRequest } from '@/lib/permissions'

export const useCurrentMedication = (enabled?: boolean, customId?: string, prepareNext = true) => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    throw new Error('No ID provided')
  }
  const medRequest = useMedicationRequestById(customId ?? id, enabled)

  const lockMedRequest = medRequest?.data ? shouldLockMedRequest(medRequest.data) : false

  useLockItemById(customId ?? id, !!(medRequest?.data && !medRequest?.data?.locked && enabled) && lockMedRequest)
  usePrepareNextRequest(enabled && prepareNext)

  return medRequest
}
