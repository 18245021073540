// import { UserRank } from '@/types/people'
import { SignOutButton, UserButton, useUser } from '@clerk/clerk-react'
import LogoutIcon from './icons/logout'
import { DiamondPlus, SquareTerminal } from 'lucide-react'
import Profile from '../pages/profile'
import { Toaster } from './toaster'
import { Suspense } from 'react'
import AiSettings from '../pages/ai-settings'
import Protect from '../Protect'

export default function UserWidget() {
  const { user } = useUser()
  const isPromptEngineer = user?.publicMetadata.roles.includes('PROMPT_ENGINEER')

  return (
    <div className='flex items-center gap-4'>
      <UserButton
        appearance={{
          elements: {
            userButtonBox: {
              flexDirection: 'row-reverse'
            }
          }
        }}
        showName
      >
        {user?.twoFactorEnabled && (
          <UserButton.MenuItems>
            <UserButton.Action
              open='profile'
              label='Extra settings'
              labelIcon={<DiamondPlus className='w-3 h-3 mx-auto' />}
            />
            {isPromptEngineer && (
              <UserButton.Action
                open='ai-settings'
                label='AI settings'
                labelIcon={<SquareTerminal className='w-3 h-3 mx-auto' />}
              />
            )}
          </UserButton.MenuItems>
        )}

        {user?.twoFactorEnabled && (
          <UserButton.UserProfilePage
            label='Extra settings'
            labelIcon={<DiamondPlus className='w-3 h-3 mx-auto' />}
            url='profile'
          >
            <Suspense fallback={<div>Loading...</div>}>
              <Profile />
            </Suspense>
            <Toaster />
          </UserButton.UserProfilePage>
        )}
        {isPromptEngineer && (
          <UserButton.UserProfilePage
            label='AI settings'
            labelIcon={<SquareTerminal className='w-3 h-3 mx-auto' />}
            url='ai-settings'
          >
            <Suspense fallback={<div>Loading...</div>}>
              <AiSettings />
            </Suspense>
            <Toaster />
          </UserButton.UserProfilePage>
        )}
      </UserButton>

      {/* <div className="flex flex-col items-start font-jakarta">
        <p className="text-base text-secondary font-bold leading-5 capitalize">{user?.fullName || user?.primaryEmailAddress?.emailAddress}</p>
        <span className="text-sm font-normal leading-5 text-custom-neutral-500">
          {UserRank.ExternalPrescriber}
        </span>
      </div> */}

      <div className='min-h-10 min-w-[1px] bg-custom-gray-1' />

      <SignOutButton>
        <button
          type='button'
          className='rounded-full bg-custom-gray-2 w-[54px] h-[54px] grid place-content-center transition-all duration-200 ease-in-out hover:bg-gray-600 opacity-60'
        >
          <LogoutIcon />
        </button>
      </SignOutButton>
    </div>
  )
}
