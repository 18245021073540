import http from '@/lib/http'
import type { BatchMedRequest, MedRequest } from '@/types/data'
import { useQuery } from '@tanstack/react-query'
import { useFlag } from '@unleash/proxy-client-react'
import { useQuestionnaire } from './useQuestionnaire'

export default function useBatchApproval({
  medRequest,
  enabled = true
}: {
  medRequest?: MedRequest
  enabled?: boolean
}) {
  const isBatchApprovalEnabled = useFlag('batch-approval')

  const { parsedQuestions } = useQuestionnaire(medRequest?.questionaire ?? '')
  const hasFlags = parsedQuestions?.some(question => question.hasFlags)

  const query = useQuery<BatchMedRequest[]>({
    queryKey: ['batchMedicationRequestById', medRequest?.id],
    queryFn: () => http.get(`/prescriber/med-requests/${medRequest?.id}/batch`),
    enabled: enabled && !medRequest?.patient?.scrData && isBatchApprovalEnabled && !!medRequest?.id && !hasFlags
  })

  return {
    ...query,
    shouldUseBatchApprovalLogic: !!query.data?.length && !hasFlags
  }
}
