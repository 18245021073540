import { cn } from '@/lib/utils'
import { ComponentPropsWithoutRef } from 'react'
import MagicWandIcon from './icons/magic-wand'

export default function AiButton({ disabled, className, children, ...props }: ComponentPropsWithoutRef<'button'>) {
  return (
    <button
      type='button'
      className={cn(
        'flex items-center gap-4 transition-all duration-200 ease-in-out',
        className,
        disabled && 'opacity-50'
      )}
      disabled={disabled}
      {...props}
    >
      <MagicWandIcon className='w-6 h-6 flex-shrink-0' />
      <span className='text-lg text-[#153CB7] font-semibold font-poppins'>{children}</span>
    </button>
  )
}
