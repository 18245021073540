import type { Investigation, MedRequest } from '@/types/data'
import { differenceInYears, format, parseISO } from 'date-fns'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useMedicationRequestStore } from './stores/medication-request'
import { toast } from '@/hooks/use-toast'
import { afterApprovalStatuses, afterRejectionStatuses } from './constants/med-requests'
import { useFlag } from '@unleash/proxy-client-react'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getOrderStatusBadgeClasses(status: string) {
  const greenStatuses = [
    'Approved for future',
    'Approved',
    'Awaiting post payment',
    'Awaiting fulfillment',
    'Picked',
    'Dispensed',
    'Checked',
    'Packed',
    'Shipped',
    'Completed'
  ]

  const amberStatuses = ['Further answers needed']

  const neutralStatuses = ['ID verified']

  if (greenStatuses.includes(status)) {
    return 'border-[#388E10] bg-[rgba(225,241,218,0.50)] text-[#388E10]'
  }
  if (amberStatuses.includes(status)) {
    return 'border-[#CC8800] bg-[#FFF1D6] text-[#CC8800]'
  }
  if (neutralStatuses.includes(status)) {
    return 'border-[#4E545E] bg-[rgba(255,255,255,0.50)] text-[#24272B]'
  }

  return 'border-[#FF4D1A] bg-[rgba(255,224,214,0.50)] text-[#FF4D1A]'
}

export function getInvestigationStatusBadgeClasses(status: string) {
  const amberStatuses = ['Started', 'Pending']
  const greenStatuses = ['Results ready']
  const neutralStatuses = ['Closed', 'Suspended']

  return cn(
    'text-[#FAFAFB]',
    greenStatuses.includes(status) && 'bg-[#388E10]',
    amberStatuses.includes(status) && 'bg-amber-500',
    neutralStatuses.includes(status) && 'bg-[#4E545E]'
  )
}

export function getPatientAgeText(dateOfBirth?: string) {
  if (!dateOfBirth) return '-'
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  const age = differenceInYears(today, birthDate)
  return `${age} (${dateOfBirth.split('-').reverse().join('/')})`
}

export function isMedRequestInEditableStage(stage: string) {
  const nonEditableStages = [
    'Packed',
    'Shipped',
    'Completed',
    'Refunded',
    'Returned',
    'Cancelled',
    'Payment Received',
    'ID Not Verified'
  ].map(entry => entry.toLowerCase())

  return !nonEditableStages.includes(stage.toLowerCase())
}

export function getAiRespTextClassName({
  allText,
  index
}: {
  allText: string[]
  index: number
}) {
  const origin = allText[index].trim()
  if (origin[0] === '-' || /^([a-z]\))/.test(origin)) {
    for (let j = index - 1; j > 0; j--) {
      if (allText[j][0] === '\n' || allText[j] === '') {
        break
      }

      const target = allText[j].trim()
      if (/^(\d+\.|[a-z]\)|-)/.test(target) && allText[index][0] !== target[0]) {
        return 'pl-6'
      }
    }
    return 'pl-4'
  }

  if (/^(\d+\.|\*)/.test(origin)) {
    return 'pl-4 '
  }

  return 'my-1'
}

export function medReqFieldValidationNotifications(action: 'approve' | 'reject' | 'hold'): boolean {
  const { justification, medicationDirection, rejectionReason, contactMessage } = useMedicationRequestStore.getState()

  if (medicationDirection.length > 2000) {
    toast({
      title: 'Medication directions too long',
      description: 'Medication directions must be less than 2000 characters in total'
    })
    return false
  }

  if (action === 'approve' && justification && justification.length > 200) {
    toast({
      title: 'Approval justification too long',
      description: 'Justification must be less than 200 characters in total'
    })
    return false
  }

  if (action === 'reject' && rejectionReason && rejectionReason.length > 1900) {
    toast({
      title: 'Rejection reason too long',
      description: 'Rejection reason must be less than 1900 characters in total'
    })
    return false
  }

  if (action === 'hold' && contactMessage && contactMessage.length > 1900) {
    toast({
      title: 'Contact message too long',
      description: 'Contact message must be less than 1900 characters in total'
    })
    return false
  }

  return true
}

export function getPreviousOrderDateToUse(order: MedRequest) {
  if (afterApprovalStatuses.includes(order.stage))
    return new Date(order.prescriptionIssueDate ?? order.idVerifiedTime ?? order.closingDate)

  if (afterRejectionStatuses.includes(order.stage))
    return new Date(order.rejectedAt ?? order.idVerifiedTime ?? order.closingDate)

  return new Date(order.idVerifiedTime ?? order.closingDate)
}

export function formatStringISOToFullDate(timestamp: string) {
  const date = parseISO(timestamp)
  return format(date, 'd MMMM yyyy hh:mm aa')
}

// It doesnt adjust the tz as its already being done in the BE
export function getCurrentUKDate() {
  const dateObject = new Date()
  const ukTime = dateObject.toLocaleString('en-GB', {
    timeZone: 'Europe/London',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  })

  // Convert to ISO format (YYYY-MM-DDTHH:MM:SS+00:00)
  const [date, time] = ukTime.split(', ')
  const [day, month, year] = date.split('/')

  dateObject.setFullYear(Number(year))
  dateObject.setMonth(Number(month) - 1) // months are 0-indexed
  dateObject.setDate(Number(day))

  return dateObject
}

export function isTodayInUK(date: Date) {
  const ukDate = getCurrentUKDate()
  return (
    date.getDate() === ukDate.getDate() &&
    date.getMonth() === ukDate.getMonth() &&
    date.getFullYear() === ukDate.getFullYear()
  )
}

export function convertDateToISOWithoutConverting(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
}

export function isMedRequestSubscription(subFrequency: string | null) {
  return !!(subFrequency && subFrequency !== 'one-time-purchase')
}

export function getMedRequestSubscriptionText(subFrequency: string | null) {
  if (!subFrequency) return ''

  return subFrequency.replace(/_/g, ' ')
}

export function useConsultationSummaryFlag(medRequest?: MedRequest): boolean {
  const isFeatureFlagEnabled = useFlag('ai_dental')

  return (
    medRequest?.orderedItem?.category === 'Dental Infection' && medRequest?.isPriority === false && isFeatureFlagEnabled
  )
}
