export default function MagicWandIcon({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5303 9.96967C11.2374 9.67678 10.7626 9.67678 10.4697 9.96967L8.46967 11.9697C8.32902 12.1103 8.25 12.3011 8.25 12.5C8.25 12.6989 8.32902 12.8897 8.46967 13.0303L18.4697 23.0303C18.7626 23.3232 19.2374 23.3232 19.5303 23.0303L21.5303 21.0303C21.8232 20.7374 21.8232 20.2626 21.5303 19.9697L11.5303 9.96967ZM11.9999 14.4392L10.0605 12.4999L10.9999 11.5605L12.9392 13.4999L11.9999 14.4392Z'
        fill='#153CB7'
      />
      <path
        d='M6 3.75C6.31383 3.75 6.59448 3.9454 6.70339 4.23972L7.03494 5.13573C7.26283 5.75159 7.74841 6.23717 8.36427 6.46506L9.26028 6.79661C9.5546 6.90552 9.75 7.18617 9.75 7.5C9.75 7.81383 9.5546 8.09448 9.26028 8.20339L8.36427 8.53494C7.74841 8.76283 7.26283 9.24841 7.03494 9.86427L6.70339 10.7603C6.59448 11.0546 6.31383 11.25 6 11.25C5.68617 11.25 5.40552 11.0546 5.29661 10.7603L4.96506 9.86427C4.73717 9.24841 4.25159 8.76283 3.63573 8.53494L2.73972 8.20339C2.4454 8.09448 2.25 7.81383 2.25 7.5C2.25 7.18617 2.4454 6.90552 2.73972 6.79661L3.63573 6.46506C4.25159 6.23717 4.73717 5.75159 4.96506 5.13573L5.29661 4.23972C5.40552 3.9454 5.68617 3.75 6 3.75Z'
        fill='#153CB7'
      />
      <path
        d='M17 1.75C17.3138 1.75 17.5945 1.9454 17.7034 2.23972L18.1455 3.43439C18.4746 4.32398 19.176 5.02537 20.0656 5.35454L21.2603 5.79661C21.5546 5.90552 21.75 6.18617 21.75 6.5C21.75 6.81383 21.5546 7.09448 21.2603 7.20339L20.0656 7.64546C19.176 7.97463 18.4746 8.67602 18.1455 9.56561L17.7034 10.7603C17.5945 11.0546 17.3138 11.25 17 11.25C16.6862 11.25 16.4055 11.0546 16.2966 10.7603L15.8545 9.56561C15.5254 8.67602 14.824 7.97463 13.9344 7.64546L12.7397 7.20339C12.4454 7.09448 12.25 6.81383 12.25 6.5C12.25 6.18617 12.4454 5.90552 12.7397 5.79661L13.9344 5.35454C14.824 5.02537 15.5254 4.32398 15.8545 3.43439L16.2966 2.23972C16.4055 1.9454 16.6862 1.75 17 1.75Z'
        fill='#153CB7'
      />
    </svg>
  )
}
