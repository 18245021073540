import http from '@/lib/http'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/services/queryClient'
import type { EditPatientFormData } from '@/components/pages/medication-request/sections/patient-data'
import type { MedRequest } from '@/types/data'
import { useToast } from './use-toast'

const useUpdatePatient = ({
  medReqId,
  patientId
}: {
  medReqId: string
  patientId: string
}) => {
  const { toast } = useToast()

  return useMutation({
    mutationKey: ['updatePatient', patientId],
    mutationFn: ({
      allergies,
      conditions,
      medications,
      socialHistory,
      familyHistory,
      otherObservations
    }: EditPatientFormData) => {
      return http.put(`/prescriber/patient/${patientId}`, {
        allergies,
        conditions,
        medications,
        socialHistory,
        familyHistory,
        otherObservations
      })
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['medicationRequestById', medReqId], (data: MedRequest) => {
        return {
          ...data,
          patient: {
            ...data.patient,
            ...variables
          }
        }
      })
    },
    onError: error => {
      console.error(error)
      toast({
        title: 'Error updating patient',
        description: 'Please try again',
        variant: 'destructive'
      })
    }
  })
}

export default useUpdatePatient
