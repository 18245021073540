import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { getPatientAgeText } from '@/lib/utils'
import type { MedRequest } from '@/types/data'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import useUpdatePatient from '@/hooks/useUpdatePatient'
import { isRoleAllowedToEditPatientData } from '@/lib/permissions'

export function Column({
  header,
  text
}: {
  header: string
  text: string | null
}) {
  return (
    <div className='w-2/12'>
      <p className='mb-2 text-[#1D1D1D] font-bold leading-[170%]'>{header}</p>
      <p className='text-custom-neutral-800 leading-[170%]'>{text ?? '-'}</p>
    </div>
  )
}

export function RedFlagIcon({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM17 15.5H13.62C13.24 15.5 12.89 15.29 12.73 14.95L12 13.5H9.5V17.75C9.5 18.16 9.16 18.5 8.75 18.5C8.34 18.5 8 18.16 8 17.75V8.5C8 7.95 8.45 7.5 9 7.5H13.38C13.76 7.5 14.11 7.71 14.27 8.05L15 9.5H17C17.55 9.5 18 9.95 18 10.5V14.5C18 15.05 17.55 15.5 17 15.5Z'
        fill='#FF8080'
      />
    </svg>
  )
}

export function PencilIcon({ ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M20 24.0001H4C2.9 24.0001 2 23.1001 2 22.0001C2 20.9001 2.9 20.0001 4 20.0001H20C21.1 20.0001 22 20.9001 22 22.0001C22 23.1001 21.1 24.0001 20 24.0001ZM13.06 5.19006L16.81 8.94006L8.04 17.7101C7.86 17.9001 7.6 18.0001 7.34 18.0001H5C4.45 18.0001 4 17.5501 4 17.0001V14.6601C4 14.3901 4.11 14.1401 4.29 13.9501L13.06 5.19006ZM17.88 7.87006L14.13 4.12006L15.96 2.29006C16.35 1.90006 16.98 1.90006 17.37 2.29006L19.71 4.63006C20.1 5.02006 20.1 5.65006 19.71 6.04006L17.88 7.87006Z'
        fill='#153CB7'
      />
    </svg>
  )
}

export function PatientRedFlag({
  patient
}: {
  patient?: MedRequest['patient']
}) {
  if (!patient?.redFlagReason) return null

  return (
    <div className='flex gap-2 bg-[#FFE5E5] rounded-2xl py-3 px-4'>
      <RedFlagIcon className='shrink-0' />
      <span className='text-[#FF8080] text-lg font-medium font-poppins'>{patient.redFlagReason}</span>
    </div>
  )
}

const editPatientFormValidationSchema = z.object({
  familyHistory: z.string().max(2000, { message: 'Family history must be less than 2000 characters' }).optional(),
  allergies: z.string().max(2000, { message: 'Allergies must be less than 2000 characters' }).optional(),
  conditions: z.string().max(2000, { message: 'Conditions must be less than 2000 characters' }).optional(),
  medications: z.string().max(2000, { message: 'Medications must be less than 2000 characters' }).optional(),
  socialHistory: z.string().max(2000, { message: 'Social history must be less than 2000 characters' }).optional(),
  otherObservations: z
    .string()
    .max(2000, { message: 'Other observations must be less than 2000 characters' })
    .optional()
})

export type EditPatientFormData = z.infer<typeof editPatientFormValidationSchema>

function MedicalHistoryRecords({
  optionalFields,
  patientId,
  medReqId,
  isMedRequest
}: {
  optionalFields: {
    name: string
    label: string
    value: string | Record<string, string>
  }[]
  patientId?: string
  medReqId?: string
  isMedRequest?: boolean
}) {
  const defaultValues = optionalFields.reduce(
    (acc, field) => {
      acc[field.name] = typeof field.value === 'string' ? field.value : JSON.stringify(field.value)
      return acc
    },
    {} as Record<string, string>
  )

  const [isEditing, setIsEditing] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(editPatientFormValidationSchema)
  })

  const { mutateAsync, isPending } = useUpdatePatient({
    medReqId: medReqId ?? '',
    patientId: patientId ?? ''
  })

  const onSubmit = async (data: EditPatientFormData) => {
    await mutateAsync(data)

    setIsEditing(false)

    reset(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-6 border-[1px] border-solid border-custom-neutral-200 rounded-[16px]'>
        <div className='flex items-center justify-between mb-4 '>
          <p className='text-[#1D1D1D] text-[18px] font-semibold leading-[133.4%]'>Medical history</p>
          {isEditing ? (
            <div className='flex gap-12'>
              <button
                type='submit'
                className={`flex w-[200px] h-12 flex-col justify-center items-center rounded-2xl bg-[#153CB7] py-2.5 px-6 hover:bg-[#0F2E8A] transition-colors duration-200 ${
                  !isDirty || isPending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                disabled={!isDirty || isPending}
              >
                <p className='text-white text-sm leading-[170%] font-semibold'>
                  {isPending ? 'Saving...' : 'Save information'}
                </p>
              </button>
              <button
                disabled={isPending}
                type='button'
                onClick={() => setIsEditing(false)}
                className='text-primary text-sm font-medium leading-[170%]'
              >
                Cancel
              </button>
            </div>
          ) : (
            isMedRequest &&
            isRoleAllowedToEditPatientData() && (
              <button
                type='button'
                onClick={() => setIsEditing(true)}
                className='text-primary text-sm font-medium leading-[170%] inline-flex items-center gap-2'
              >
                Edit information <PencilIcon />
              </button>
            )
          )}
        </div>
        <div className='flex flex-wrap gap-10 w-full items-start'>
          {optionalFields.map((field, index) => {
            const displayValue = typeof field.value === 'string' ? field.value : JSON.stringify(field.value)

            const isLeftColumn = index % 2 === 0 && optionalFields[index + 1]

            return (
              <div key={index} className='flex gap-[50px] w-[calc(50%_-_51px)]'>
                <div className='flex flex-col gap-2 w-full'>
                  <p className='text-[#1D1D1D] font-bold text-base leading-[170%] capitalize'>{field.label}</p>
                  {isEditing ? (
                    <>
                      <textarea
                        className={`text-sm leading-6 border ${errors[field.name] ? 'border-red-500' : 'border-custom-neutral-200'} rounded-2xl pt-4 px-3 w-full h-[104px] resize-none`}
                        {...register(field.name)}
                      />
                      {errors[field.name] && (
                        <p className='text-red-500 text-sm mt-1'>
                          {errors[field.name]?.message?.toString() || 'This field has an error'}
                        </p>
                      )}
                    </>
                  ) : (
                    <p className='text-custom-neutral-800 font-regular whitespace-pre-wrap text-base leading-[170%]'>
                      {displayValue || '-'}
                    </p>
                  )}
                </div>
                {isLeftColumn && <div className='min-h-full w-[1px] bg-custom-neutral-700' />}
              </div>
            )
          })}
        </div>
      </div>
    </form>
  )
}

export default function PatientData({
  patientOrigin = 'medication-request'
}: {
  patientOrigin?: 'investigation' | 'medication-request'
}) {
  const isMedRequest = patientOrigin === 'medication-request'
  const medRequestQuery = useCurrentMedication(isMedRequest)
  const medRequest = medRequestQuery?.data
  const { data: investigation } = useCurrentInvestigation(patientOrigin === 'investigation')

  const patient = medRequest?.patient ?? investigation?.patient

  const patientAgeText = useMemo(() => getPatientAgeText(patient?.dob), [patient?.dob])

  const patientName = patient?.firstName ? `${patient?.firstName} ${patient?.lastName}` : '-'

  const optionalFields = useMemo(() => {
    return (
      ['familyHistory', 'allergies', 'conditions', 'medications', 'socialHistory', 'otherObservations'] as Array<
        keyof MedRequest['patient']
      >
    ).map(field => {
      let label: string = field
      if (field === 'otherObservations') {
        label = 'Other'
      } else if (field === 'socialHistory') {
        label = 'Social history'
      } else if (field === 'familyHistory') {
        label = 'Family history'
      }

      return {
        name: field,
        label,
        value: patient?.[field] ?? ''
      }
    })
  }, [patient])

  return (
    <div id='patient-data'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-4'>Patient data</p>
      <div className='flex flex-col gap-6 rounded-2xl border border-custom-neutral-700 bg-white px-12 py-8'>
        <PatientRedFlag patient={patient} />
        <div className='flex min-h-[88px] justify-between'>
          <Column header='Patient name' text={patientName} />
          <div className='min-h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Age (D.O.B.)' text={patientAgeText} />
          <div className='min-h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Gender' text={patient?.gender as string} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          {medRequest && <Column header='Postcode' text={medRequest.orderPostCode} />}
        </div>

        <MedicalHistoryRecords
          optionalFields={optionalFields}
          patientId={patient?.id}
          isMedRequest={isMedRequest}
          medReqId={medRequest?.id ?? ''}
        />
      </div>
    </div>
  )
}
